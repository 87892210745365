<template lang="pug">
  v-container
    v-row
      v-col(lg='4' cols='12')
        splr-cat-create
      v-col(lg='8' cols='12')
        splr-cat-table
</template>
<script>
export default {
  name: 'SupplierCategory',
  components: {
    splrCatCreate: () => import('./Create'),
    splrCatTable: () => import('./Table'),
  },
}
</script>
